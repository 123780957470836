@tailwind base;
@tailwind components;
@tailwind utilities;

/* colors */
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Poppins:wght@100;200;300;400&display=swap");

:root {
  --pink: #ffc0d4;
  --red: #ff3900;
  --blue: #0067ec;
  --yellow: #ffdd00;
  --whitecream: #fffaf0;
  font-size: 16px;
  margin: 0;
  cursor: default;
}

.scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: var(--pink) var(--whitecream);
}

.scroller::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--whitecream);
  border-radius: 10px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: 10px;
}

/* Handle on hover */

body {
  background: var(--whitecream);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: grid;
  font-family: "Poppins", sans-serif;
}

button,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: none;
  color: black;
  cursor: pointer;
}

h3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
}

var {
  font-style: normal;
}

a:hover {
  cursor: pointer;
}

.yellowH:hover {
  background: var(--yellow);
}

/* This is a fix for safari */
.lottieHeight svg {
  height: auto !important;
}

/* Big screen */

/* Small screen and tablet */

/* Tablet */

@media screen and (max-width: 650px) {
  .yellowH:hover {
    background: var(--whitecream);
  }
}
