/* content images */
.hero {
  transform: translateY(0);
  grid-column: 1 / -1;
  grid-row: 1;
  /* background: var(--whitecream); */
}

.hero svg {
  height: auto !important;
}

.lottie-appear {
  max-height: 200vh;
}

.lottie-enter {
  max-height: 200vh;
}

.lottie-enter-done {
  height: auto;
  transition: all 1000ms;
  transform: translateY(-1400px);
  max-height: 0;
}

@media screen and (max-width: 800px) {
  .lottie-enter-done {
    transition: all 2000ms;
    height: auto;
  }
}
