.social-bar {
  position: fixed;
  right: 20px;
  bottom: 60px;

  display: grid;
  grid-gap: 2rem;

  justify-items: center;
}

.social-bar * {
  color: black;
  font-size: 24px;
}

.vertical {
  transform: rotate(-90deg);
    max-width: 24px;
    padding: 2vh 0;
}
